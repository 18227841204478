import React from 'react'
import {graphql, Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './blog.module.css'

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

export default function Template({data}) {
  const {markdownRemark} = data
  const {frontmatter, html} = markdownRemark
  const {title} = frontmatter
  return (
    <Layout>
      <SEO title={title} />
      <div className={styles.blogContainer}>
        <Link to={'/faq'}>← Back to FAQ</Link>
        <h1>{title}</h1>
        <div
          className={styles.blogContent}
          dangerouslySetInnerHTML={{__html: html}}
        />
      </div>
    </Layout>
  )
}
